import * as React from 'react'

import {
  ThreeDCraftsTopicIcon,
  ThreeDSvgsTopicIcon,
  AccuquiltTopicIcon,
  ApparelDesignTopicIcon,
  AppliqueTopicIcon,
  ArtsAndCraftsTopicIcon,
  BakingAndCakeDecorationsTopicIcon,
  BasketryTopicIcon,
  BeadingTopicIcon,
  BibleJournalingTopicIcon,
  BobbinLaceMakingTopicIcon,
  BookbindingTopicIcon,
  BrotherTopicIcon,
  BulletJournalingTopicIcon,
  BundlesTopicIcon,
  CalligraphyTopicIcon,
  CandleMakingTopicIcon,
  CardMakingTopicIcon,
  CeramicsTopicIcon,
  CfSparkTopicIcon,
  CfStudioTopicIcon,
  ClassesTopicIcon,
  ClayTopicIcon,
  CookingTopicIcon,
  CraftersCompanionTopicIcon,
  CraftsTopicIcon,
  CreativeBusinessTopicIcon,
  CreativeInspirationTopicIcon,
  CreativeWritingTopicIcon,
  CricutTopicIcon,
  CrochetTopicIcon,
  DecoupageTopicIcon,
  DieCuttingTopicIcon,
  DigitalDesignsTopicIcon,
  DollMakingTopicIcon,
  DrawingTopicIcon,
  EmbossingTopicIcon,
  EmbroideryTopicIcon,
  EpsonTopicIcon,
  EtchingTopicIcon,
  EtsyStoreTopicIcon,
  FeltCraftsTopicIcon,
  FloralDesignTopicIcon,
  FontsTopicIcon,
  FoodCraftTopicIcon,
  GardenAndOutdoorTopicIcon,
  GlassBeadmakingTopicIcon,
  GlassEtchingTopicIcon,
  GlowforgeTopicIcon,
  GraphicDesignsTopicIcon,
  GraphicsTopicIcon,
  HandEmbroideryTopicIcon,
  HomeDecorTopicIcon,
  HtvrontTopicIcon,
  IllustratingTopicIcon,
  JewelryMakingTopicIcon,
  JournalingTopicIcon,
  JunkJournalingTopicIcon,
  KdpTopicIcon,
  KidsCraftsTopicIcon,
  KnittingTopicIcon,
  LaserCuttingTopicIcon,
  LeatherworkingTopicIcon,
  MachineEmbroideryTopicIcon,
  MacrameTopicIcon,
  MetalCraftsTopicIcon,
  MixedMediaTopicIcon,
  ModelBuildingTopicIcon,
  MosaicTopicIcon,
  NeedleFeltingTopicIcon,
  NeedlepointTopicIcon,
  NeedleworkTopicIcon,
  OrganizingAndPlanningTopicIcon,
  OrigamiTopicIcon,
  OtherTopicIcon,
  PaintingTopicIcon,
  PaperCraftingTopicIcon,
  PaperModellingTopicIcon,
  PapermakingTopicIcon,
  PartyDecorationsTopicIcon,
  PatchworkTopicIcon,
  PhotographyTopicIcon,
  PngsTopicIcon,
  PodTopicIcon,
  PotteryTopicIcon,
  PrintingTopicIcon,
  PunchNeedlingTopicIcon,
  QuillingTopicIcon,
  QuiltingTopicIcon,
  ResinTopicIcon,
  RhinestonesTopicIcon,
  RugmakingAndTuftingTopicIcon,
  SawgrassTopicIcon,
  ScrapbookingTopicIcon,
  SculpturesTopicIcon,
  SeasonalCraftsTopicIcon,
  SewingTopicIcon,
  SilhouetteTopicIcon,
  SizzixTopicIcon,
  SoapMakingTopicIcon,
  SpinningTopicIcon,
  StainedGlassTopicIcon,
  StampingAndStampmakingTopicIcon,
  StockPhotosTopicIcon,
  SublimationTopicIcon,
  SuncatchersTopicIcon,
  TeachingMaterialsTopicIcon,
  TextilesTopicIcon,
  TieDyeTopicIcon,
  ToolsTopicIcon,
  UpcyclingTopicIcon,
  UpholsteryTopicIcon,
  VevorTopicIcon,
  VinylTopicIcon,
  WaterslideDecalTopicIcon,
  WeRTopicIcon,
  WeavingTopicIcon,
  WoodCraftTopicIcon,
  WreathMakingTopicIcon,
  XyronTopicIcon,
  SupportTopicIcon,
} from '@ask-cf/assets/icons/topics-icons'

export type Topic = {
  id: number
  title: string
  slug: string
  questionCount: number
  icon: React.ReactElement
  iconComponent: React.FC<React.SVGProps<SVGSVGElement>>
  bgColor: string
  iconColor: string
  textColor: string
  followers: number
}

export const DEFAULT_TOPIC_UI = {
  icon: <OtherTopicIcon className="h-full w-full" />,
  iconComponent: OtherTopicIcon,
  bgColor: 'bg-[#FCEDF9]',
  iconColor: 'text-[#812168]',
  textColor: 'text-[#4E525C]',
}

export const SUPPORT_TOPIC_SLUG = 'support-questions'

export const TOPICS_LIST: Topic[] = [
  {
    id: 1,
    title: '3D Crafts',
    slug: '3d-crafts',
    questionCount: 323,
    icon: <ThreeDCraftsTopicIcon className="h-full w-full" />,
    iconComponent: ThreeDCraftsTopicIcon,
    bgColor: 'bg-[#F3F0FC]',
    iconColor: 'text-[#2B2874]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 2,
    title: '3D SVGs',
    slug: '3d-svgs',
    questionCount: 323,
    icon: <ThreeDSvgsTopicIcon className="h-full w-full" />,
    iconComponent: ThreeDSvgsTopicIcon,
    bgColor: 'bg-[#F7E8DA]',
    iconColor: 'text-[#C46308]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 3,
    title: 'Accuquilt',
    slug: 'accuquilt',
    questionCount: 323,
    icon: <AccuquiltTopicIcon className="h-full w-full" />,
    iconComponent: AccuquiltTopicIcon,
    bgColor: 'bg-[#F5F7D0]',
    iconColor: 'text-[#B2BC35]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 4,
    title: 'Apparel Design',
    slug: 'apparel-design',
    questionCount: 323,
    icon: <ApparelDesignTopicIcon className="h-full w-full" />,
    iconComponent: ApparelDesignTopicIcon,
    bgColor: 'bg-[#E5FFFB]',
    iconColor: 'text-[#107B6D]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 5,
    title: 'Applique',
    slug: 'applique',
    questionCount: 323,
    icon: <AppliqueTopicIcon className="h-full w-full" />,
    iconComponent: AppliqueTopicIcon,
    bgColor: 'bg-[#FCF0F0]',
    iconColor: 'text-[#881719]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 6,
    title: 'Arts & Crafts',
    slug: 'arts-and-crafts',
    questionCount: 323,
    icon: <ArtsAndCraftsTopicIcon className="h-full w-full" />,
    iconComponent: ArtsAndCraftsTopicIcon,
    bgColor: 'bg-[#F0FAFC]',
    iconColor: 'text-[#57A5B6]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 131,
    title: 'Baby Lock',
    slug: 'baby-lock',
    questionCount: 323,
    icon: <SewingTopicIcon className="h-full w-full" />,
    iconComponent: SewingTopicIcon,
    bgColor: 'bg-[#FFF7F0]',
    iconColor: 'text-[#F47621]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 7,
    title: 'Baking & Cake Decorating',
    slug: 'baking-and-cake-decorating',
    questionCount: 323,
    icon: <BakingAndCakeDecorationsTopicIcon className="h-full w-full" />,
    iconComponent: BakingAndCakeDecorationsTopicIcon,
    bgColor: 'bg-[#E3FCEA]',
    iconColor: 'text-[#598F68]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 8,
    title: 'Basketry',
    slug: 'basketry',
    questionCount: 323,
    icon: <BasketryTopicIcon className="h-full w-full" />,
    iconComponent: BasketryTopicIcon,
    bgColor: 'bg-[#EEE8FC]',
    iconColor: 'text-[#443893]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 9,
    title: 'Beading',
    slug: 'beading',
    questionCount: 323,
    icon: <BeadingTopicIcon className="h-full w-full" />,
    iconComponent: BeadingTopicIcon,
    bgColor: 'bg-[#F2FADC]',
    iconColor: 'text-[#94C43E]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 10,
    title: 'Bible Journaling',
    slug: 'bible-journaling',
    questionCount: 323,
    icon: <BibleJournalingTopicIcon className="h-full w-full" />,
    iconComponent: BibleJournalingTopicIcon,
    bgColor: 'bg-[#F7E6E9]',
    iconColor: 'text-[#351B1F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 11,
    title: 'Bobbin Lace Making',
    slug: 'bobbin-lace-making',
    questionCount: 323,
    icon: <BobbinLaceMakingTopicIcon className="h-full w-full" />,
    iconComponent: BobbinLaceMakingTopicIcon,
    bgColor: 'bg-[#FAFCD9]',
    iconColor: 'text-[#565F2A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 12,
    title: 'Bookbinding',
    slug: 'bookbinding',
    questionCount: 323,
    icon: <BookbindingTopicIcon className="h-full w-full" />,
    iconComponent: BookbindingTopicIcon,
    bgColor: 'bg-[#F0FBFC]',
    iconColor: 'text-[#074D57]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 13,
    title: 'Brother',
    slug: 'brother',
    questionCount: 323,
    icon: <BrotherTopicIcon className="h-full w-full" />,
    iconComponent: BrotherTopicIcon,
    bgColor: 'bg-[#F0F2FC]',
    iconColor: 'text-[#234095]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 14,
    title: 'Bullet Journaling',
    slug: 'bullet-journaling',
    questionCount: 323,
    icon: <BulletJournalingTopicIcon className="h-full w-full" />,
    iconComponent: BulletJournalingTopicIcon,
    bgColor: 'bg-[#FCEDED]',
    iconColor: 'text-[#BE2426]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 15,
    title: 'Bundles',
    slug: 'bundles',
    questionCount: 323,
    icon: <BundlesTopicIcon className="h-full w-full" />,
    iconComponent: BundlesTopicIcon,
    bgColor: 'bg-[#C5F0DE]',
    iconColor: 'text-[#00A25E]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 16,
    title: 'Calligraphy',
    slug: 'calligraphy',
    questionCount: 323,
    icon: <CalligraphyTopicIcon className="h-full w-full" />,
    iconComponent: CalligraphyTopicIcon,
    bgColor: 'bg-[#EDE7FF]',
    iconColor: 'text-[#4C4A9F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 17,
    title: 'Candle Making',
    slug: 'candle-making',
    questionCount: 323,
    icon: <CandleMakingTopicIcon className="h-full w-full" />,
    iconComponent: CandleMakingTopicIcon,
    bgColor: 'bg-[#FFF0C8]',
    iconColor: 'text-[#E0A611]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 18,
    title: 'Card Making',
    slug: 'card-making',
    questionCount: 323,
    icon: <CardMakingTopicIcon className="h-full w-full" />,
    iconComponent: CardMakingTopicIcon,
    bgColor: 'bg-[#E3E6FF]',
    iconColor: 'text-[#5060AC]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 19,
    title: 'Ceramics',
    slug: 'ceramics',
    questionCount: 323,
    icon: <CeramicsTopicIcon className="h-full w-full" />,
    iconComponent: CeramicsTopicIcon,
    bgColor: 'bg-[#DEFCEE]',
    iconColor: 'text-[#0C8547]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 20,
    title: 'Spark AI',
    slug: 'spark-ai',
    questionCount: 323,
    icon: <CfSparkTopicIcon className="h-full w-full" />,
    iconComponent: CfSparkTopicIcon,
    bgColor: 'bg-[#DCE5FA]',
    iconColor: 'text-[#4E6DB4]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 21,
    title: 'Studio',
    slug: 'studio',
    questionCount: 323,
    icon: <CfStudioTopicIcon className="h-full w-full" />,
    iconComponent: CfStudioTopicIcon,
    bgColor: 'bg-[#DCFAE1]',
    iconColor: 'text-[#35A348]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 22,
    title: 'Classes',
    slug: 'classes',
    questionCount: 323,
    icon: <ClassesTopicIcon className="h-full w-full" />,
    iconComponent: ClassesTopicIcon,
    bgColor: 'bg-[#F2D5EA]',
    iconColor: 'text-[#6F0151]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 23,
    title: 'Clay',
    slug: 'clay',
    questionCount: 323,
    icon: <ClayTopicIcon className="h-full w-full" />,
    iconComponent: ClayTopicIcon,
    bgColor: 'bg-[#FCF9DE]',
    iconColor: 'text-[#696128]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 24,
    title: 'Cooking',
    slug: 'cooking',
    questionCount: 323,
    icon: <CookingTopicIcon className="h-full w-full" />,
    iconComponent: CookingTopicIcon,
    bgColor: 'bg-[#F5EFDC]',
    iconColor: 'text-[#BE9503]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 25,
    title: "Crafter's Companion",
    slug: 'crafters-companion',
    questionCount: 323,
    icon: <CraftersCompanionTopicIcon className="h-full w-full" />,
    iconComponent: CraftersCompanionTopicIcon,
    bgColor: 'bg-[#F8EDFC]',
    iconColor: 'text-[#703086]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 26,
    title: 'Crafts',
    slug: 'crafts',
    questionCount: 323,
    icon: <CraftsTopicIcon className="h-full w-full" />,
    iconComponent: CraftsTopicIcon,
    bgColor: 'bg-[#EEFCE3]',
    iconColor: 'text-[#346933]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 27,
    title: 'Creative Business',
    slug: 'creative-business',
    questionCount: 323,
    icon: <CreativeBusinessTopicIcon className="h-full w-full" />,
    iconComponent: CreativeBusinessTopicIcon,
    bgColor: 'bg-[#DEEEFC]',
    iconColor: 'text-[#0362BA]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 28,
    title: 'Creative Inspiration',
    slug: 'creative-inspiration',
    questionCount: 323,
    icon: <CreativeInspirationTopicIcon className="h-full w-full" />,
    iconComponent: CreativeInspirationTopicIcon,
    bgColor: 'bg-[#E3EFFC]',
    iconColor: 'text-[#2A61AD]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 29,
    title: 'Creative Writing',
    slug: 'creative-writing',
    questionCount: 323,
    icon: <CreativeWritingTopicIcon className="h-full w-full" />,
    iconComponent: CreativeWritingTopicIcon,
    bgColor: 'bg-[#E3FCF6]',
    iconColor: 'text-[#126F59]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 30,
    title: 'Cricut',
    slug: 'cricut',
    questionCount: 323,
    icon: <CricutTopicIcon className="h-full w-full" />,
    iconComponent: CricutTopicIcon,
    bgColor: 'bg-[#E1FAF2]',
    iconColor: 'text-[#31AE87]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 31,
    title: 'Crochet',
    slug: 'crochet',
    questionCount: 323,
    icon: <CrochetTopicIcon className="h-full w-full" />,
    iconComponent: CrochetTopicIcon,
    bgColor: 'bg-[#E4E7F7]',
    iconColor: 'text-[#2A3589]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 32,
    title: 'Decoupage',
    slug: 'decoupage',
    questionCount: 323,
    icon: <DecoupageTopicIcon className="h-full w-full" />,
    iconComponent: DecoupageTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#A6712A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 33,
    title: 'Die-Cutting',
    slug: 'die-cutting',
    questionCount: 323,
    icon: <DieCuttingTopicIcon className="h-full w-full" />,
    iconComponent: DieCuttingTopicIcon,
    bgColor: 'bg-[#E5FFF2]',
    iconColor: 'text-[#268053]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 34,
    title: 'Digital Designs',
    slug: 'digital-designs',
    questionCount: 323,
    icon: <DigitalDesignsTopicIcon className="h-full w-full" />,
    iconComponent: DigitalDesignsTopicIcon,
    bgColor: 'bg-[#EBE3FC]',
    iconColor: 'text-[#442E81]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 35,
    title: 'Doll Making',
    slug: 'doll-making',
    questionCount: 323,
    icon: <DollMakingTopicIcon className="h-full w-full" />,
    iconComponent: DollMakingTopicIcon,
    bgColor: 'bg-[#E6FAF3]',
    iconColor: 'text-[#227657]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 36,
    title: 'Drawing',
    slug: 'drawing',
    questionCount: 323,
    icon: <DrawingTopicIcon className="h-full w-full" />,
    iconComponent: DrawingTopicIcon,
    bgColor: 'bg-[#E3F3FC]',
    iconColor: 'text-[#0A76B4]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 37,
    title: 'Embossing',
    slug: 'embossing',
    questionCount: 323,
    icon: <EmbossingTopicIcon className="h-full w-full" />,
    iconComponent: EmbossingTopicIcon,
    bgColor: 'bg-[#E3E1FC]',
    iconColor: 'text-[#3F4B9F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 38,
    title: 'Embroidery',
    slug: 'embroidery',
    questionCount: 323,
    icon: <EmbroideryTopicIcon className="h-full w-full" />,
    iconComponent: EmbroideryTopicIcon,
    bgColor: 'bg-[#F6E1FA]',
    iconColor: 'text-[#802B8F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 39,
    title: 'Epson',
    slug: 'epson',
    questionCount: 323,
    icon: <EpsonTopicIcon className="h-full w-full" />,
    iconComponent: EpsonTopicIcon,
    bgColor: 'bg-[#E3E7FC]',
    iconColor: 'text-[#2A337D]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 40,
    title: 'Etching',
    slug: 'etching',
    questionCount: 323,
    icon: <EtchingTopicIcon className="h-full w-full" />,
    iconComponent: EtchingTopicIcon,
    bgColor: 'bg-[#E8F1FC]',
    iconColor: 'text-[#265A99]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 41,
    title: 'Etsy Store',
    slug: 'etsy-store',
    questionCount: 323,
    icon: <EtsyStoreTopicIcon className="h-full w-full" />,
    iconComponent: EtsyStoreTopicIcon,
    bgColor: 'bg-[#FAE0D2]',
    iconColor: 'text-[#ED5600]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 42,
    title: 'Felt Crafts',
    slug: 'felt-crafts',
    questionCount: 323,
    icon: <FeltCraftsTopicIcon className="h-full w-full" />,
    iconComponent: FeltCraftsTopicIcon,
    bgColor: 'bg-[#DEFCEB]',
    iconColor: 'text-[#277446]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 43,
    title: 'Floral Design',
    slug: 'floral-design',
    questionCount: 323,
    icon: <FloralDesignTopicIcon className="h-full w-full" />,
    iconComponent: FloralDesignTopicIcon,
    bgColor: 'bg-[#FCE3FB]',
    iconColor: 'text-[#A93F97]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 44,
    title: 'Fonts',
    slug: 'fonts',
    questionCount: 323,
    icon: <FontsTopicIcon className="h-full w-full" />,
    iconComponent: FontsTopicIcon,
    bgColor: 'bg-[#E5F6FF]',
    iconColor: 'text-[#6BBFEB]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 45,
    title: 'Food Craft',
    slug: 'food-craft',
    questionCount: 323,
    icon: <FoodCraftTopicIcon className="h-full w-full" />,
    iconComponent: FoodCraftTopicIcon,
    bgColor: 'bg-[#E2DCF5]',
    iconColor: 'text-[#332E8A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 46,
    title: 'Garden & Outdoor',
    slug: 'garden-and-outdoor',
    questionCount: 323,
    icon: <GardenAndOutdoorTopicIcon className="h-full w-full" />,
    iconComponent: GardenAndOutdoorTopicIcon,
    bgColor: 'bg-[#F0FCF8]',
    iconColor: 'text-[#43AE89]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 47,
    title: 'Glass Beadmaking',
    slug: 'glass-beadmaking',
    questionCount: 323,
    icon: <GlassBeadmakingTopicIcon className="h-full w-full" />,
    iconComponent: GlassBeadmakingTopicIcon,
    bgColor: 'bg-[#ECE9F5]',
    iconColor: 'text-[#5251A2]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 48,
    title: 'Glass Etching',
    slug: 'glass-etching',
    questionCount: 323,
    icon: <GlassEtchingTopicIcon className="h-full w-full" />,
    iconComponent: GlassEtchingTopicIcon,
    bgColor: 'bg-[#DADEF7]',
    iconColor: 'text-[#001376]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 49,
    title: 'Glowforge',
    slug: 'glowforge',
    questionCount: 323,
    icon: <GlowforgeTopicIcon className="h-full w-full" />,
    iconComponent: GlowforgeTopicIcon,
    bgColor: 'bg-[#F0FBFC]',
    iconColor: 'text-[#33A2AF]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 50,
    title: 'Graphic Design',
    slug: 'graphic-design',
    questionCount: 323,
    icon: <GraphicDesignsTopicIcon className="h-full w-full" />,
    iconComponent: GraphicDesignsTopicIcon,
    bgColor: 'bg-[#EEFCED]',
    iconColor: 'text-[#45B649]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 51,
    title: 'Graphics',
    slug: 'graphics',
    questionCount: 323,
    icon: <GraphicsTopicIcon className="h-full w-full" />,
    iconComponent: GraphicsTopicIcon,
    bgColor: 'bg-[#FCFCD2]',
    iconColor: 'text-[#8D8E35]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 52,
    title: 'Hand Embroidery',
    slug: 'hand-embroidery',
    questionCount: 323,
    icon: <HandEmbroideryTopicIcon className="h-full w-full" />,
    iconComponent: HandEmbroideryTopicIcon,
    bgColor: 'bg-[#DEFCE3]',
    iconColor: 'text-[#1A5C2F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 53,
    title: 'Home Decor',
    slug: 'home-decor',
    questionCount: 323,
    icon: <HomeDecorTopicIcon className="h-full w-full" />,
    iconComponent: HomeDecorTopicIcon,
    bgColor: 'bg-[#F0FCFA]',
    iconColor: 'text-[#309885]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 132,
    title: 'Husqvarna',
    slug: 'husqvarna',
    questionCount: 323,
    icon: <SewingTopicIcon className="h-full w-full" />,
    iconComponent: SewingTopicIcon,
    bgColor: 'bg-[#E6EAF7]',
    iconColor: 'text-[#425EAB]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 54,
    title: 'HTVRONT',
    slug: 'htvront',
    questionCount: 323,
    icon: <HtvrontTopicIcon className="h-full w-full" />,
    iconComponent: HtvrontTopicIcon,
    bgColor: 'bg-[#FFF7F0]',
    iconColor: 'text-[#F47621]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 55,
    title: 'Illustrating',
    slug: 'illustrating',
    questionCount: 323,
    icon: <IllustratingTopicIcon className="h-full w-full" />,
    iconComponent: IllustratingTopicIcon,
    bgColor: 'bg-[#F0E6FC]',
    iconColor: 'text-[#372468]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 129,
    title: 'Janome',
    slug: 'janome',
    questionCount: 323,
    icon: <SewingTopicIcon className="h-full w-full" />,
    iconComponent: SewingTopicIcon,
    bgColor: 'bg-[#FCDEDE]',
    iconColor: 'text-[#E35153]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 56,
    title: 'Jewelry Making',
    slug: 'jewelry-making',
    questionCount: 323,
    icon: <JewelryMakingTopicIcon className="h-full w-full" />,
    iconComponent: JewelryMakingTopicIcon,
    bgColor: 'bg-[#FCF5E1]',
    iconColor: 'text-[#E6B821]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 57,
    title: 'Journaling',
    slug: 'journaling',
    questionCount: 323,
    icon: <JournalingTopicIcon className="h-full w-full" />,
    iconComponent: JournalingTopicIcon,
    bgColor: 'bg-[#F2F9FC]',
    iconColor: 'text-[#01577C]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 58,
    title: 'Junk Journaling',
    slug: 'junk-journaling',
    questionCount: 323,
    icon: <JunkJournalingTopicIcon className="h-full w-full" />,
    iconComponent: JunkJournalingTopicIcon,
    bgColor: 'bg-[#E8FCFA]',
    iconColor: 'text-[#0B3732]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 59,
    title: 'KDP',
    slug: 'kdp',
    questionCount: 323,
    icon: <KdpTopicIcon className="h-full w-full" />,
    iconComponent: KdpTopicIcon,
    bgColor: 'bg-[#FCEEDE]',
    iconColor: 'text-[#EB8923]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 60,
    title: 'Kids Crafts',
    slug: 'kids-crafts',
    questionCount: 323,
    icon: <KidsCraftsTopicIcon className="h-full w-full" />,
    iconComponent: KidsCraftsTopicIcon,
    bgColor: 'bg-[#DAF7DA]',
    iconColor: 'text-[#14A94B]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 61,
    title: 'Knitting',
    slug: 'knitting',
    questionCount: 323,
    icon: <KnittingTopicIcon className="h-full w-full" />,
    iconComponent: KnittingTopicIcon,
    bgColor: 'bg-[#F6E1FA]',
    iconColor: 'text-[#802C8E]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 62,
    title: 'Laser Cutting',
    slug: 'laser-cutting',
    questionCount: 323,
    icon: <LaserCuttingTopicIcon className="h-full w-full" />,
    iconComponent: LaserCuttingTopicIcon,
    bgColor: 'bg-[#FADCDC]',
    iconColor: 'text-[#AF2125]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 63,
    title: 'Leatherworking',
    slug: 'leatherworking',
    questionCount: 323,
    icon: <LeatherworkingTopicIcon className="h-full w-full" />,
    iconComponent: LeatherworkingTopicIcon,
    bgColor: 'bg-[#FCEBDE]',
    iconColor: 'text-[#B67A4F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 64,
    title: 'Machine Embroidery',
    slug: 'machine-embroidery',
    questionCount: 323,
    icon: <MachineEmbroideryTopicIcon className="h-full w-full" />,
    iconComponent: MachineEmbroideryTopicIcon,
    bgColor: 'bg-[#E6E3FC]',
    iconColor: 'text-[#2B2655]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 65,
    title: 'Macramé',
    slug: 'macrame',
    questionCount: 323,
    icon: <MacrameTopicIcon className="h-full w-full" />,
    iconComponent: MacrameTopicIcon,
    bgColor: 'bg-[#FAEFDC]',
    iconColor: 'text-[#D0912A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 66,
    title: 'Metal Crafts',
    slug: 'metal-crafts',
    questionCount: 323,
    icon: <MetalCraftsTopicIcon className="h-full w-full" />,
    iconComponent: MetalCraftsTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#785923]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 67,
    title: 'Mixed Media',
    slug: 'mixed-media',
    questionCount: 323,
    icon: <MixedMediaTopicIcon className="h-full w-full" />,
    iconComponent: MixedMediaTopicIcon,
    bgColor: 'bg-[#F6E1FA]',
    iconColor: 'text-[#802A90]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 68,
    title: 'Model Building',
    slug: 'model-building',
    questionCount: 323,
    icon: <ModelBuildingTopicIcon className="h-full w-full" />,
    iconComponent: ModelBuildingTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#704D1F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 69,
    title: 'Mosaic',
    slug: 'mosaic',
    questionCount: 323,
    icon: <MosaicTopicIcon className="h-full w-full" />,
    iconComponent: MosaicTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#CE912A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 70,
    title: 'Needle Felting',
    slug: 'needle-felting',
    questionCount: 323,
    icon: <NeedleFeltingTopicIcon className="h-full w-full" />,
    iconComponent: NeedleFeltingTopicIcon,
    bgColor: 'bg-[#D3D4F0]',
    iconColor: 'text-[#444DA0]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 71,
    title: 'Needlepoint',
    slug: 'needlepoint',
    questionCount: 323,
    icon: <NeedlepointTopicIcon className="h-full w-full" />,
    iconComponent: NeedlepointTopicIcon,
    bgColor: 'bg-[#DAE7F7]',
    iconColor: 'text-[#265A99]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 72,
    title: 'Needlework',
    slug: 'needlework',
    questionCount: 323,
    icon: <NeedleworkTopicIcon className="h-full w-full" />,
    iconComponent: NeedleworkTopicIcon,
    bgColor: 'bg-[#FCEBE8]',
    iconColor: 'text-[#D6432C]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 73,
    title: 'Organizing & Planning',
    slug: 'organizing-and-planning',
    questionCount: 323,
    icon: <OrganizingAndPlanningTopicIcon className="h-full w-full" />,
    iconComponent: OrganizingAndPlanningTopicIcon,
    bgColor: 'bg-[#FCF4ED]',
    iconColor: 'text-[#F37021]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 74,
    title: 'Origami',
    slug: 'origami',
    questionCount: 323,
    icon: <OrigamiTopicIcon className="h-full w-full" />,
    iconComponent: OrigamiTopicIcon,
    bgColor: 'bg-[#E3EBFA]',
    iconColor: 'text-[#3D62AD]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 75,
    title: 'Other',
    slug: 'other',
    questionCount: 323,
    icon: <OtherTopicIcon className="h-full w-full" />,
    iconComponent: OtherTopicIcon,
    bgColor: 'bg-[#FCEDF9]',
    iconColor: 'text-[#812168]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 76,
    title: 'Painting',
    slug: 'painting',
    questionCount: 323,
    icon: <PaintingTopicIcon className="h-full w-full" />,
    iconComponent: PaintingTopicIcon,
    bgColor: 'bg-[#FCF2F0]',
    iconColor: 'text-[#BE2F26]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 77,
    title: 'Paper Crafting',
    slug: 'paper-crafting',
    questionCount: 323,
    icon: <PaperCraftingTopicIcon className="h-full w-full" />,
    iconComponent: PaperCraftingTopicIcon,
    bgColor: 'bg-[#FAEBED]',
    iconColor: 'text-[#F2798F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 78,
    title: 'Paper Modelling',
    slug: 'paper-modelling',
    questionCount: 323,
    icon: <PaperModellingTopicIcon className="h-full w-full" />,
    iconComponent: PaperModellingTopicIcon,
    bgColor: 'bg-[#E5FCE3]',
    iconColor: 'text-[#1A783D]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 79,
    title: 'Papermaking',
    slug: 'papermaking',
    questionCount: 323,
    icon: <PapermakingTopicIcon className="h-full w-full" />,
    iconComponent: PapermakingTopicIcon,
    bgColor: 'bg-[#F3F0FC]',
    iconColor: 'text-[#3A2C76]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 80,
    title: 'Party Decorations',
    slug: 'party-decorations',
    questionCount: 323,
    icon: <PartyDecorationsTopicIcon className="h-full w-full" />,
    iconComponent: PartyDecorationsTopicIcon,
    bgColor: 'bg-[#FCEEE3]',
    iconColor: 'text-[#F58432]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 81,
    title: 'Patchwork',
    slug: 'patchwork',
    questionCount: 323,
    icon: <PatchworkTopicIcon className="h-full w-full" />,
    iconComponent: PatchworkTopicIcon,
    bgColor: 'bg-[#F8DCFC]',
    iconColor: 'text-[#964399]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 82,
    title: 'Photography',
    slug: 'photography',
    questionCount: 323,
    icon: <PhotographyTopicIcon className="h-full w-full" />,
    iconComponent: PhotographyTopicIcon,
    bgColor: 'bg-[#F6E1FA]',
    iconColor: 'text-[#802A90]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 83,
    title: 'PNGs',
    slug: 'pngs',
    questionCount: 323,
    icon: <PngsTopicIcon className="h-full w-full" />,
    iconComponent: PngsTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#986E2A]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 84,
    title: 'POD',
    slug: 'pod',
    questionCount: 323,
    icon: <PodTopicIcon className="h-full w-full" />,
    iconComponent: PodTopicIcon,
    bgColor: 'bg-[#EDF0FC]',
    iconColor: 'text-[#21235B]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 85,
    title: 'Pottery',
    slug: 'pottery',
    questionCount: 323,
    icon: <PotteryTopicIcon className="h-full w-full" />,
    iconComponent: PotteryTopicIcon,
    bgColor: 'bg-[#FAE6F2]',
    iconColor: 'text-[#AE206E]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 86,
    title: 'Printing',
    slug: 'printing',
    questionCount: 323,
    icon: <PrintingTopicIcon className="h-full w-full" />,
    iconComponent: PrintingTopicIcon,
    bgColor: 'bg-[#FFECD9]',
    iconColor: 'text-[#DB842C]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 87,
    title: 'Punch Needling',
    slug: 'punch-needling',
    questionCount: 323,
    icon: <PunchNeedlingTopicIcon className="h-full w-full" />,
    iconComponent: PunchNeedlingTopicIcon,
    bgColor: 'bg-[#E5FCE3]',
    iconColor: 'text-[#245D2F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 89,
    title: 'Quilting',
    slug: 'quilting',
    questionCount: 323,
    icon: <QuiltingTopicIcon className="h-full w-full" />,
    iconComponent: QuiltingTopicIcon,
    bgColor: 'bg-[#E1FCDE]',
    iconColor: 'text-[#244E25]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 90,
    title: 'Resin',
    slug: 'resin',
    questionCount: 323,
    icon: <ResinTopicIcon className="h-full w-full" />,
    iconComponent: ResinTopicIcon,
    bgColor: 'bg-[#FCDEDE]',
    iconColor: 'text-[#E35153]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 91,
    title: 'Rhinestones',
    slug: 'rhinestones',
    questionCount: 323,
    icon: <RhinestonesTopicIcon className="h-full w-full" />,
    iconComponent: RhinestonesTopicIcon,
    bgColor: 'bg-[#FCF7E3]',
    iconColor: 'text-[#E7B821]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 92,
    title: 'Rugmaking & Tufting',
    slug: 'rugmaking-and-tufting',
    questionCount: 323,
    icon: <RugmakingAndTuftingTopicIcon className="h-full w-full" />,
    iconComponent: RugmakingAndTuftingTopicIcon,
    bgColor: 'bg-[#DCFADF]',
    iconColor: 'text-[#1E4822]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 93,
    title: 'Sawgrass',
    slug: 'sawgrass',
    questionCount: 323,
    icon: <SawgrassTopicIcon className="h-full w-full" />,
    iconComponent: SawgrassTopicIcon,
    bgColor: 'bg-[#FCE3EC]',
    iconColor: 'text-[#E9417C]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 130,
    title: 'SINGER',
    slug: 'singer',
    questionCount: 323,
    icon: <SewingTopicIcon className="h-full w-full" />,
    iconComponent: SewingTopicIcon,
    bgColor: 'bg-[#FADCDC]',
    iconColor: 'text-[#AF2125]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 94,
    title: 'Scrapbooking',
    slug: 'scrapbooking',
    questionCount: 323,
    icon: <ScrapbookingTopicIcon className="h-full w-full" />,
    iconComponent: ScrapbookingTopicIcon,
    bgColor: 'bg-[#FCEDF4]',
    iconColor: 'text-[#921A43]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 95,
    title: 'Sculptures',
    slug: 'sculptures',
    questionCount: 323,
    icon: <SculpturesTopicIcon className="h-full w-full" />,
    iconComponent: SculpturesTopicIcon,
    bgColor: 'bg-[#E8E6FC]',
    iconColor: 'text-[#1F1953]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 96,
    title: 'Quilling',
    slug: 'quilling',
    questionCount: 323,
    icon: <QuillingTopicIcon className="h-full w-full" />,
    iconComponent: QuillingTopicIcon,
    bgColor: 'bg-[#F0F6FC]',
    iconColor: 'text-[#265A99]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 104,
    title: 'Seasonal Crafts',
    slug: 'seasonal-crafts',
    questionCount: 323,
    icon: <SeasonalCraftsTopicIcon className="h-full w-full" />,
    iconComponent: SeasonalCraftsTopicIcon,
    bgColor: 'bg-[#FFF0E5]',
    iconColor: 'text-[#D66027]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 105,
    title: 'Sewing',
    slug: 'sewing',
    questionCount: 323,
    icon: <SewingTopicIcon className="h-full w-full" />,
    iconComponent: SewingTopicIcon,
    bgColor: 'bg-[#F6E1FA]',
    iconColor: 'text-[#802B8F]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 106,
    title: 'Silhouette',
    slug: 'silhouette',
    questionCount: 323,
    icon: <SilhouetteTopicIcon className="h-full w-full" />,
    iconComponent: SilhouetteTopicIcon,
    bgColor: 'bg-[#E1F4FA]',
    iconColor: 'text-[#4CAEC9]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 107,
    title: 'Sizzix',
    slug: 'sizzix',
    questionCount: 323,
    icon: <SizzixTopicIcon className="h-full w-full" />,
    iconComponent: SizzixTopicIcon,
    bgColor: 'bg-[#EDFCFA]',
    iconColor: 'text-[#4E998B]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 108,
    title: 'Soap Making',
    slug: 'soap-making',
    questionCount: 323,
    icon: <SoapMakingTopicIcon className="h-full w-full" />,
    iconComponent: SoapMakingTopicIcon,
    bgColor: 'bg-[#DEFCF7]',
    iconColor: 'text-[#0FB090]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 109,
    title: 'Spinning',
    slug: 'spinning',
    questionCount: 323,
    icon: <SpinningTopicIcon className="h-full w-full" />,
    iconComponent: SpinningTopicIcon,
    bgColor: 'bg-[#F6FADC]',
    iconColor: 'text-[#4B5224]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 110,
    title: 'Stained Glass',
    slug: 'stained-glass',
    questionCount: 323,
    icon: <StainedGlassTopicIcon className="h-full w-full" />,
    iconComponent: StainedGlassTopicIcon,
    bgColor: 'bg-[#FCDEE3]',
    iconColor: 'text-[#F05873]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 111,
    title: 'Stamping & Stamp Making',
    slug: 'stamping-and-stamp-making',
    questionCount: 323,
    icon: <StampingAndStampmakingTopicIcon className="h-full w-full" />,
    iconComponent: StampingAndStampmakingTopicIcon,
    bgColor: 'bg-[#FCF0F3]',
    iconColor: 'text-[#E93F67]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 112,
    title: 'Stockphotos',
    slug: 'stockphotos',
    questionCount: 323,
    icon: <StockPhotosTopicIcon className="h-full w-full" />,
    iconComponent: StockPhotosTopicIcon,
    bgColor: 'bg-[#F7DFEC]',
    iconColor: 'text-[#D31774]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 113,
    title: 'Sublimation',
    slug: 'sublimation',
    questionCount: 323,
    icon: <SublimationTopicIcon className="h-full w-full" />,
    iconComponent: SublimationTopicIcon,
    bgColor: 'bg-[#E1FAE1]',
    iconColor: 'text-[#16A84B]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 114,
    title: 'Suncatchers',
    slug: 'suncatchers',
    questionCount: 323,
    icon: <SuncatchersTopicIcon className="h-full w-full" />,
    iconComponent: SuncatchersTopicIcon,
    bgColor: 'bg-[#FCF4E3]',
    iconColor: 'text-[#CE9019]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 115,
    title: 'Teaching Materials',
    slug: 'teaching-materials',
    questionCount: 323,
    icon: <TeachingMaterialsTopicIcon className="h-full w-full" />,
    iconComponent: TeachingMaterialsTopicIcon,
    bgColor: 'bg-[#E3F3FC]',
    iconColor: 'text-[#074365]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 116,
    title: 'Textiles',
    slug: 'textiles',
    questionCount: 323,
    icon: <TextilesTopicIcon className="h-full w-full" />,
    iconComponent: TextilesTopicIcon,
    bgColor: 'bg-[#DAF7E9]',
    iconColor: 'text-[#0E8F50]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 117,
    title: 'Tie-Dye',
    slug: 'tie-dye',
    questionCount: 323,
    icon: <TieDyeTopicIcon className="h-full w-full" />,
    iconComponent: TieDyeTopicIcon,
    bgColor: 'bg-[#E6DCFA]',
    iconColor: 'text-[#4E499E]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 118,
    title: 'Tools',
    slug: 'tools',
    questionCount: 323,
    icon: <ToolsTopicIcon className="h-full w-full" />,
    iconComponent: ToolsTopicIcon,
    bgColor: 'bg-[#E6EAF7]',
    iconColor: 'text-[#425EAB]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 119,
    title: 'Upcycling',
    slug: 'upcycling',
    questionCount: 323,
    icon: <UpcyclingTopicIcon className="h-full w-full" />,
    iconComponent: UpcyclingTopicIcon,
    bgColor: 'bg-[#E4FCE3]',
    iconColor: 'text-[#1CB24B]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 120,
    title: 'Upholstery',
    slug: 'upholstery',
    questionCount: 323,
    icon: <UpholsteryTopicIcon className="h-full w-full" />,
    iconComponent: UpholsteryTopicIcon,
    bgColor: 'bg-[#F7F2EB]',
    iconColor: 'text-[#6B4018]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 121,
    title: 'VEVOR',
    slug: 'vevor',
    questionCount: 323,
    icon: <VevorTopicIcon className="h-full w-full" />,
    iconComponent: VevorTopicIcon,
    bgColor: 'bg-[#FCF0EB]',
    iconColor: 'text-[#F16024]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 122,
    title: 'Vinyl',
    slug: 'vinyl',
    questionCount: 323,
    icon: <VinylTopicIcon className="h-full w-full" />,
    iconComponent: VinylTopicIcon,
    bgColor: 'bg-[#E2FFD9]',
    iconColor: 'text-[#65BC46]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 123,
    title: 'Waterslide Decal',
    slug: 'waterslide-decal',
    questionCount: 323,
    icon: <WaterslideDecalTopicIcon className="h-full w-full" />,
    iconComponent: WaterslideDecalTopicIcon,
    bgColor: 'bg-[#EBF1FC]',
    iconColor: 'text-[#3F65B0]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 124,
    title: 'We R',
    slug: 'we-r',
    questionCount: 323,
    icon: <WeRTopicIcon className="h-full w-full" />,
    iconComponent: WeRTopicIcon,
    bgColor: 'bg-[#E8F9FA]',
    iconColor: 'text-[#45C2C7]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 125,
    title: 'Weaving',
    slug: 'weaving',
    questionCount: 323,
    icon: <WeavingTopicIcon className="h-full w-full" />,
    iconComponent: WeavingTopicIcon,
    bgColor: 'bg-[#D7FCE8]',
    iconColor: 'text-[#0B6D38]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 126,
    title: 'Wood Craft',
    slug: 'wood-craft',
    questionCount: 323,
    icon: <WoodCraftTopicIcon className="h-full w-full" />,
    iconComponent: WoodCraftTopicIcon,
    bgColor: 'bg-[#FAEADC]',
    iconColor: 'text-[#A16730]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 127,
    title: 'Wreath Making',
    slug: 'wreath-making',
    questionCount: 323,
    icon: <WreathMakingTopicIcon className="h-full w-full" />,
    iconComponent: WreathMakingTopicIcon,
    bgColor: 'bg-[#DEF3FC]',
    iconColor: 'text-[#196C91]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 128,
    title: 'Xyron',
    slug: 'xyron',
    questionCount: 323,
    icon: <XyronTopicIcon className="h-full w-full" />,
    iconComponent: XyronTopicIcon,
    bgColor: 'bg-[#FCEEEB]',
    iconColor: 'text-[#ED4724]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 129,
    title: 'Support',
    slug: SUPPORT_TOPIC_SLUG,
    questionCount: 323,
    icon: <SupportTopicIcon className="h-full w-full" />,
    iconComponent: SupportTopicIcon,
    bgColor: 'bg-[#DAE7F7]',
    iconColor: 'text-[#265A99]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
  {
    id: 130,
    title: 'Collaging',
    slug: 'collaging',
    questionCount: 323,
    icon: <OtherTopicIcon className="h-full w-full" />,
    iconComponent: OtherTopicIcon,
    bgColor: 'bg-[#FCEDF9]',
    iconColor: 'text-[#812168]',
    textColor: 'text-[#4E525C]',
    followers: 2514,
  },
]

export const TOPICS_LIST_FOR_SIDEBAR: Topic[] = [
  TOPICS_LIST.find(topic => topic.title === 'Fonts')!,
  TOPICS_LIST.find(topic => topic.title === 'Paper Crafting')!,
  TOPICS_LIST.find(topic => topic.title === 'Graphics')!,
  TOPICS_LIST.find(topic => topic.title === 'Cricut')!,
  TOPICS_LIST.find(topic => topic.title === 'Card Making')!,
  TOPICS_LIST.find(topic => topic.title === 'Crafts')!,
  TOPICS_LIST.find(topic => topic.title === 'Sublimation')!,
  TOPICS_LIST.find(topic => topic.title === 'Vinyl')!,
  TOPICS_LIST.find(topic => topic.title === 'Tools')!,
  TOPICS_LIST.find(topic => topic.title === 'Apparel Design')!,
]
